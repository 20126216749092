import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { createContext, useContext } from "react";
import { auth } from '../firebaseConfig';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({})

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider()
        return signInWithPopup(auth, provider)
    }

    const logout = () => {
        signOut(auth)
    }

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (currentuser)=>{
        setUser(currentuser)
        // console.log("User Data:- ", currentuser)
      })

      return () => {
        unsubscribe()
      }
    }, [])
    
  
    return (
      <AuthContext.Provider value={{googleSignIn, logout, user}}>
        {children}
      </AuthContext.Provider>
    );
  };
  
  export const userAuth = () => useContext(AuthContext); 